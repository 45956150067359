// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Hero from "../components/agence-amazon/hero"
import Benefits from "../components/agence-amazon/benefits"
import LogoSection from "../components/plateforme-saas/logo-section"
import GetSupport from "../components/agence-amazon/get-support"
import SuccessClient from "../components/agence-amazon/success-client"
import SuccessClientMobile from "../components/agence-amazon/success-client-mobile"
import Cards from "../components/agence-amazon/cards"

const PlateformeSaaS = ({ data, pageContext }) => {
  const { allContentfulFooter } = data
  const langKey = pageContext.langKey

  return (
    <>
      <Helmet>
        <title>360 Amazon Agency - Seelk</title>
        <meta
          name="description"
          content="Gain performance on Amazon thanks to our 360 approach: Consulting and strategy, Retail media campaigns, Brand Content, Operational support, Trainings..."
        />
        <meta name="format-detection" content="telephone=no" />
        {langKey === "en" && <link rel="alternate" hreflang="en-us" />}
        {langKey === "fr" && <link rel="alternate" hreflang="fr" />}
      </Helmet>
      <Layout
        isDarkMode
        langKey={langKey}
        allContentfulFooter={allContentfulFooter}
      >
        <div
          sx={{
            display: "block",
            gridTemplateColumns: "1fr",
            gridGap: 4,
          }}
        >
          <Hero langKey={langKey} />
          <Benefits langKey={langKey} />
          <Cards langKey={langKey} />
          <LogoSection langKey={langKey} />
          <SuccessClient langKey={langKey} />
          <SuccessClientMobile langKey={langKey} />
          <GetSupport langKey={langKey} />
        </div>
      </Layout>
    </>
  )
}

export default PlateformeSaaS

export const data = graphql`
  query AmazonAgencyQuery($langKey: String!) {
    allContentfulFooter(
      filter: { node_locale: { eq: $langKey }, agencyServices: { ne: null } }
    ) {
      edges {
        node {
          node_locale
          seelkOffices {
            officeCity
            officeAddress
            officeTelephoneNumber
          }
          saasForAmazon
          agencyServices
          aboutSeelk
          copyrights
          contact
          connect
        }
      }
    }
  }
`
